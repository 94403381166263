import { Home } from './pages/Home';
import Router from './routes/router';
import GlobalStyles from './styles/globalStyles';

function App() {
  return (
    <>
      <GlobalStyles />
      <Router />
    </>
  );
}

export default App;
