import styled from "styled-components";

export const PageContainer = styled.div``;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 3rem;

  p {
    padding: 0 6.8rem;
    margin-bottom: 2.6rem 0;
    text-align: center;
    width: 100%;
    color: var(--white);
    font-size: 1.5rem;
  }

  @media screen and (max-width: 575px) {
    p {
      padding: 0;
      text-align: start;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 0;
    p {
      font-size: 1rem;
    }
  }
`;

export const ContentCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  justify-items: center;
  align-items: center;
  margin: 3rem 0;

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
  }
`;

export const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 400;
  margin: 2rem 0 1rem;
  letter-spacing: 0;
  strong {
    font-weight: 700;
  }
  @media screen and (max-width: 575px) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

export const ProjectCard = styled.article`
  margin-top: 3rem;
  background-color: var(--white);
  color: var(--black);
  width: 25rem;
  max-height: 30rem;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  @media screen and (max-width: 768px) {
    width: 19rem;
  }
`;

export const CardImg = styled.div`
  position: relative;
  top: -50px;
  height: 100px;
  display: flex;
  justify-content: center;

  .card-img div {
    width: 90%;
  }

  .card-imgs {
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const CardImage = styled.img`
  margin-top: -3rem;
  height: 9rem;
  width: 85%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const ProjectInfo = styled.div`
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  top: -50px;

  span {
    color: var(--black);
    font-size: 1.3rem;
  }
`;

export const ProjectTitle = styled.div`
  font-weight: 700;
  font-size: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--black);

  @media screen and (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Account = styled.a`
  font-weight: bold;
`;

export const Tag = styled.span`
  font-weight: lighter;
  color: grey;
`;

export const Pv = styled.div`
  transition: transform 0.3s;

  ${ProjectCard}:hover & {
    transform: scale(1.12);
  }

  .pv:hover {
    transform: scale(1.12);
  }
`;
