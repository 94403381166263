import styled, { css } from "styled-components";

import { Trophy, ChefHat, IndustryRounded, Shop } from "thebest-icons";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 575px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Divider = styled.div`
  width: 25%;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const LineTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .firstBar {
    border-radius: 1rem 0 0 1rem;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;

    .firstBar {
      border-radius: 1rem 1rem 0 0;
    }
  }
`;
export const LineBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .lastBar {
    border-radius: 0 1rem 1rem 0;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;

    .lastBar {
      border-radius: 0 0 1rem 1rem;
    }
  }
`;

export const LineBarTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  background: var(--principal-color);
  color: var(--white);
  margin-bottom: 2rem;

  strong {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 575px) {
    height: 20rem;
    width: 4rem;
    margin-right: 1.2rem;
    margin-bottom: 0;
    strong {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 510px) {
    margin-right: 1.3rem;
  }
  @media screen and (max-width: 460px) {
    width: 3rem;
    margin-right: 1.5rem;
  }
  @media screen and (max-width: 430px) {
    margin-right: 1.4rem;
  }
  @media screen and (max-width: 410px) {
    margin-right: 1.5rem;
  }
  @media screen and (max-width: 400px) {
    margin-right: 2.2rem;
    width: 4rem;
    strong {
      font-size: 1rem;
    }
  }
`;

export const LineBarBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  background: var(--black);
  color: var(--white);
  margin-top: 2rem;

  strong {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 575px) {
    height: 20rem;
    width: 4rem;
    margin-left: 1.2rem;
    margin-top: 0;
    strong {
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 510px) {
    margin-left: 1.4rem;
  }
  @media screen and (max-width: 460px) {
    margin-left: 1.5rem;
    width: 3rem;
  }
  @media screen and (max-width: 430px) {
    margin-left: 1.3rem;
    width: 2.6rem;
  }
  @media screen and (max-width: 430px) {
    margin-left: 1.5rem;
  }
  @media screen and (max-width: 400px) {
    width: 4rem;
    margin-left: 2.2rem;
    strong {
      font-size: 1rem;
    }
  }
`;

export const TriangleTop = styled.div`
  width: 0;
  height: 0;
  border-left: 1.4rem solid transparent;
  border-right: 1.4rem solid transparent;
  border-bottom: 2rem solid var(--principal-color);
  @media screen and (max-width: 575px) {
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-right: 1.8rem solid var(--principal-color);
  }
  @media screen and (max-width: 575px) {
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 1.2rem solid var(--principal-color);
  }
`;
export const TriangleBottom = styled.div`
  width: 0;
  height: 0;
  border-left: 1.4rem solid transparent;
  border-right: 1.4rem solid transparent;
  border-top: 2rem solid var(--black);
  @media screen and (max-width: 575px) {
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-left: 1.8rem solid var(--black);
  }
  @media screen and (max-width: 575px) {
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1.2rem solid var(--black);
  }
`;
export const CircleTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: var(--black);
  @media screen and (max-width: 1200px) {
    width: 9rem;
    height: 9rem;
  }
  @media screen and (max-width: 1000px) {
    width: 8rem;
    height: 8rem;
  }
  @media screen and (max-width: 760px) {
    width: 6rem;
    height: 6rem;
  }
  @media screen and (max-width: 575px) {
    width: 5rem;
    height: 5rem;
  }
  @media screen and (max-width: 400px) {
    width: 6rem;
    height: 3.5rem;
  }
`;
export const CircleBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: var(--black);
  @media screen and (max-width: 1200px) {
    width: 9rem;
    height: 9rem;
  }
  @media screen and (max-width: 1000px) {
    width: 8rem;
    height: 8rem;
  }
  @media screen and (max-width: 760px) {
    width: 6rem;
    height: 6rem;
  }
  @media screen and (max-width: 575px) {
    width: 5rem;
    height: 5rem;
  }
  @media screen and (max-width: 400px) {
    width: 6rem;
    height: 3.5rem;
  }
`;
export const BarTop = styled.div`
  width: 0.2rem;
  height: 5rem;
  margin-bottom: 2rem;
  background: var(--black);
  @media screen and (max-width: 1200px) {
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 1000px) {
    margin-bottom: 4rem;
  }
  @media screen and (max-width: 760px) {
    margin-bottom: 6rem;
  }
  @media screen and (max-width: 575px) {
    height: 0.2rem;
    width: 5rem;
    margin-bottom: 0rem;
    margin-right: 1rem;
  }
  @media screen and (max-width: 400px) {
    width: 3rem;
  }
`;
export const BarBottom = styled.div`
  width: 0.2rem;
  height: 6rem;
  margin-top: 2rem;
  background: var(--black);
  @media screen and (max-width: 1200px) {
    margin-top: 3rem;
  }
  @media screen and (max-width: 1000px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 760px) {
    margin-top: 6rem;
  }
  @media screen and (max-width: 575px) {
    height: 0.2rem;
    width: 5rem;
    margin-top: 0rem;
    margin-left: 1rem;
  }
  @media screen and (max-width: 400px) {
    width: 3rem;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 13.5rem;
  margin: 2rem 0.3rem;
  @media screen and (max-width: 575px) {
    height: 15rem;
    max-height: 15rem;
    width: 9rem;
    overflow: hidden;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 2rem;
  text-align: center;
  color: var(--principal-color);
  letter-spacing: 0.05rem;
  @media screen and (max-width: 1050px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 0.7rem;
  }
`;

export const TextContent = styled.p`
  text-align: center;
  overflow: hidden;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--white);
  letter-spacing: 0.05rem;
 
  @media screen and (max-width: 500px) {
    line-height: 1rem;
  }
`;

export const Icons = css`
  width: 4rem;
  height: 4rem;

  color: var(--white);

  @media screen and (max-width: 768px) {
    width: 2rem;
    height: 2rem;
  }
`;

export const IndustryIcon = styled(IndustryRounded)`
  ${Icons}
`;
export const StartIcon = styled(ChefHat)`
  ${Icons}
`;
export const TrophyIcon = styled(Trophy)`
  ${Icons}
`;
export const ShopIcon = styled(Shop)`
  ${Icons}
`;
