import { Banner } from "../../components/Banners";
import { Footer } from "../../components/Footer";
import Teste from "../../assets/banner_lojas.jpg";
import LojaLondrinaGleba from "../../assets/Gracco-Gleba.png";
import LojaLondrinaCentro from "../../assets/Gracco-Express-Londrina.png";
import Apucarana from "../../assets/Gracco-Apucarana.png";
import Maringa from "../../assets/Gracco-Maringa.png";
import Ibipora from "../../assets/Gracco-Ibipora.png";
import Curitiba from "../../assets/Gracco-Arapongas.png";

import {
  AboutContainer,
  CardImage,
  CardImg,
  Container,
  ContentCards,
  Flex,
  PageContainer,
  ProjectCard,
  ProjectInfo,
  ProjectTitle,
  Tag,
  Title,
} from "./styles";

import Navbar from "../../components/Navbar";

export const Lojas = () => {
  const GraccoStores = [
    {
      img: LojaLondrinaGleba,
      title: "Londrina, PR",
      neighborhood: "Gleba",
      description:
        "Rua Caracas, 133. Horário de funcionamento: segunda à domingo, das 18:30 às 23:00.",
    },
    {
      img: LojaLondrinaCentro,
      title: "Londrina, PR",
      neighborhood: "Centro",
      description:
        "Rua Paranaguá, 955. Horário de funcionamento: segunda à domingo, das 18:30 às 23:00.",
    },
    {
      img: Apucarana,
      title: "Apucarana, PR",
      neighborhood: "Centro",
      description:
        "Rua Doutor Oswaldo Cruz, 1134, sala 02. Horário de funcionamento: segunda à quinta, das 18:45 às 22:45.\nSexta, das 18:30 às 22:45.\nSábado e domingo: 18:45 às 22:45.",
    },
    {
      img: Teste,
      title: "Arapongas, PR",
      neighborhood: "Centro",
      description: "Rua Uirapuru, 934. " + 
      "Horário de funcionamento: segunda à domingo, das 18:30 às 23:00",
    },
    {
      img: Maringa,
      title: "Maringá, PR",
      neighborhood: "Zona 04",
      description:
        "Avenida Cidade de Leiria, 638. Horário de funcionamento: terça à domingo, das 18:30 às 23:00.",
    },
    {
      img: Ibipora,
      title: "Ibiporã, PR",
      neighborhood: "Eloy Brusch",
      description:
        "Rua Luís Ferrari, 165.Horário de funcionamento: segunda à domingo, das 18:30 às 23:00.",
    },
    {
      img: Curitiba,
      title: "Curitiba, PR",
      neighborhood: "",
      description: "Em breve!",
    },
  ];

  return (
    <PageContainer>
      <Navbar />
      <Banner
        img={Teste}
        alt="Banner da página Sobre nós. Na foto, imagens de uma franquia"
        text={false}
      />
      <Container>
        <AboutContainer>
          <Title>
            Nossas <strong>lojas</strong>
          </Title>
          <ContentCards>
            {GraccoStores.map((item) => (
              <ProjectCard>
                <CardImg>
                  <CardImage src={item.img} className="card-imgs pv delete" />
                </CardImg>
                <ProjectInfo>
                  <Flex>
                    <ProjectTitle>{item.title}</ProjectTitle>
                    <Tag>{item.neighborhood}</Tag>
                  </Flex>
                  <span>{item.description}</span>
                </ProjectInfo>
              </ProjectCard>
            ))}
          </ContentCards>
        </AboutContainer>
      </Container>

      <Footer active={true} />
    </PageContainer>
  );
};
