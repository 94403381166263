import { Banner } from "../../components/Banners";
import { Footer } from "../../components/Footer";
import { TimeLine } from "../../components/TimeLine";
import Teste from "../../assets/banner_sobre.png";

import ImgSobre from "../../assets/sobre.png";

import {
  AboutContainer,
  Container,
  Icons,
  IconsCards,
  IconsContainer,
  Title,
  IconTitle,
  IconText,
  TrajectoryContainer,
  TrajectoryImg,
  TrajectoryTitle,
  TrajectoryText,
  TrajectoryTextContainer,
  TimeLineContainer,
  VideoContainer,
  VideoRep,
  PrinciplesContainer,
  PrinciplesCardsContainer,
  PrinciplesCards,
  PrinciplesText,
  PrinciplesIcon,
  PrinciplesIconContainer,
  PrinciplesTitle,
  PrinciplesTextContainer,
  PageContainer,
  BarGraphIcon,
  StartIcon,
  TrophyIcon,
  UserGraduateIcon,
  HandsIcon,
  IconContent,
  StoreIcon,
  PeopleCommunityIcon,
} from "./styles";

import Navbar from "../../components/Navbar";

export const Sobre = () => {
  const menuIcons = [
    {
      id: 1,
      icon: <PeopleCommunityIcon />,
      title: (
        <>
          <b>
            + DE <strong>120</strong>
          </b>
          <b>COLABORADORES</b>
        </>
      ),
      text: " ",
    },
    {
      id: 2,
      icon: <StoreIcon />,
      title: (
        <>
          <b>
            + DE <strong>7</strong>
          </b>
          <b> LOJAS ABERTAS</b>
        </>
      ),
      text: " ",
    },
  ];

  const principlesOdd = [
    {
      id: 1,
      icon: <BarGraphIcon />,
      title: "1. Faça o seu melhor",
      text: "Em todas as ações do seu dia, e em tudo o que você faz, você se pergunta se está fazendo o seu melhor? Seja com amigos, família, religião e principalmente trabalho, se cobre e faça o seu melhor! É assim que você se desenvolve! Somos uma empresa que estamos em melhoria contínua e mudando sempre, mas uma coisa que nunca irá mudar é que sempre vamos fazer o nosso MELHOR! PROGRESSO = Disciplina + Trabalho Duro!",
    },

    {
      id: 2,
      icon: <StartIcon />,
      title: "2. Faça o melhor para o cliente",
      text: "Fazer o nosso melhor para o cliente sempre nos colocará na direção certa. Toda vez em que você tiver alguma dúvida sobre sua postura com o cliente, pergunte-se: Essa é a maneira que eu gostaria de ser atendido? Essa é postura que eu gostaria que as pessoas que eu mais amo, fossem atendidas? Basta refletir sobre isso. Existe apenas uma pessoas que pode tirar qualquer um do jogo, essa pessoa é o CLIENTE!",
    },
    {
      id: 3,
      icon: <TrophyIcon />,
      title: "3. Sucesso NÃO aceita preguiça",
      text: "Não toleramos vagabundagem! E o que isso significa? Errar faz parte do processo de desenvolvimento, todos erramos. Mas, não podemos gostar dessa dor. Sinta-a uma vez, para não acontecer mais. Não aceitamos pessoas que só culpam os outros e não olham os próprios erros. Não tenha preguiça com você. Adiar e postergar as coisas é adiar o seu desenvolvimento, seus sonhos e o nosso progresso. Faça o quanto antes puder. Organize, se planeja e EXECUTE.",
    },

    {
      id: 4,
      icon: <UserGraduateIcon />,
      title: "4. Humildade e bom senso",
      text: "Em toda conversa dentro da empresa, pergunte-se naquele momento se você é um professor ou aluno no assunto. Saiba se posicionar e respeitar as posições de conhecimento. Tenha ATITUDE para mostrar aquilo que você domina, mas também tenha HUMILDADE, para ouvir e aprender sempre que necessário.",
    },
    {
      id: 5,
      icon: <HandsIcon />,
      title: "5. Familia e time",
      text: "Respeito, convívio, amizade e bons relacionamentos. Em alguns casos passamos mais tempo com as pessoas no trabalho do que com pessoas de nossa família. Isso se dá pois criamos laços e vínculos com as pessoas, e isso é muito bom. Quem que não gosta de trabalhar com pessoas que preenchem nosso dia e deixam ele melhor? Seja essa pessoa que sempre melhora o dia de alguém e quer o melhor de quem está ao seu lado!",
    },
    {
      id: 6,
      icon: <HandsIcon />,
      title: "6. Lealdade",
      text: "Responsabilidade e Transparência. A sua palavra deve ter o poder de valer mais do que qualquer contrato ou documento. Tenha lealdade com a sua palavra e valorize o tempo do outro. Dê prioridade no que importa, não deixe seu tempo ao léu para os outros decidam.",
    },
  ];

  return (
    <PageContainer>
      <Navbar />
      <Banner
        img={Teste}
        alt="Banner da página Sobre nós. Na foto, imagens de uma franquia"
        text={false}
      />
      <Container>
        <AboutContainer>
          <Title>
            Quem <strong>somos?</strong>
          </Title>

          <IconsContainer>
            {menuIcons.map((cards) => (
              <IconsCards key={cards.id}>
                <IconContent>{cards.icon}</IconContent>
                <IconTitle>{cards.title}</IconTitle>
                <IconText>{cards.text}</IconText>
              </IconsCards>
            ))}
          </IconsContainer>
        </AboutContainer>

        <TrajectoryContainer>
          <TrajectoryImg src={ImgSobre} />
          <TrajectoryTextContainer>
            <TrajectoryTitle>
              Os irmãos <strong>GRACCO</strong>.
            </TrajectoryTitle>
            <TrajectoryText>
              <p>
                A Gracco Burger foi fundada em 2018 por dois irmãos com um
                sonho: criar o melhor hambúrguer já produzido em sua região.
                Determinados a alcançar esse objetivo, eles embarcaram em uma
                jornada de busca por conhecimento, viajando pelo Brasil e pelo
                mundo em busca das melhores práticas e técnicas do ramo. Com
                esse sonho em mente e muitos estudos, eles começaram a
                desenvolver seus primeiros hambúrgueres na garagem de casa. O
                sabor dos hambúrgueres era tão excepcional que em pouco tempo a
                fama se espalhou pelas cidades vizinhas. As pessoas começaram a
                fazer viagens até a cidade de Ibiporã só para provar esse
                hambúrguer tão famoso.
              </p>

              <p>
                O sucesso foi crescendo e, com o tempo, a empresa também
                cresceu. Hoje, nossa missão é levar esse produto prestigiado
                para todo o Brasil. Nossa meta é garantir que todas as pessoas
                possam desfrutar de um Gracco Burger. Queremos proporcionar a
                todos a oportunidade de experimentar a qualidade e o sabor
                únicos que conquistaram tantos admiradores. Estamos
                comprometidos em expandir e compartilhar essa delícia com o
                máximo de pessoas possível. 
              </p>
            </TrajectoryText>
          </TrajectoryTextContainer>
        </TrajectoryContainer>

        <TimeLineContainer>
          <Title style={{ margin: "0 0 3rem" }}>Linha do tempo</Title>
          <TimeLine />
        </TimeLineContainer>

        <VideoContainer>
          <Title>Conheça mais sobre nós!</Title>

          <VideoRep src="https://www.youtube.com/embed/jWFYNvvMdDs">
            Seu navegador não suporta HTML5.
          </VideoRep>
        </VideoContainer>
        <PrinciplesContainer>
          <Title>Princípios da Gracco Burguer</Title>
          <PrinciplesCardsContainer>
            {principlesOdd.map((cards) => (
              <PrinciplesCards key={cards.id}>
                <PrinciplesIconContainer>
                  <PrinciplesIcon>{cards.icon}</PrinciplesIcon>
                </PrinciplesIconContainer>
                <PrinciplesTextContainer>
                  <PrinciplesTitle>{cards.title}</PrinciplesTitle>
                  <PrinciplesText>{cards.text}</PrinciplesText>
                </PrinciplesTextContainer>
              </PrinciplesCards>
            ))}
          </PrinciplesCardsContainer>
        </PrinciplesContainer>
      </Container>

      <Footer active={true} />
    </PageContainer>
  );
};
