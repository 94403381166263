import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import LogoImg from '../../assets/logo.png'
import {
  NavbarContainer,
  Logo,
  NavMenu,
  NavItem,
  NavLinks,
  MobileIcon,
  CloseIcon,
  MenuIcon,
} from "./styles";

const Navbar: React.FC = () => {
  const location = useLocation();
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  return (
    <NavbarContainer>
      <Logo src={LogoImg} alt="logo da Gracco Burger"/>

      <MobileIcon onClick={handleClick}>
        {click ? <CloseIcon /> : <MenuIcon />}
      </MobileIcon>

      <NavMenu open={click} onClick={handleClick}>
        <NavItem isActive={location.pathname === "/"}>
          <NavLinks to="/">Início</NavLinks>
        </NavItem>
        <NavItem isActive={location.pathname === "/sobre"}>
          <NavLinks to="/sobre">Nossa história</NavLinks>
        </NavItem>
        <NavItem isActive={location.pathname === "/lojas"}>
          <NavLinks to="/lojas">Nossas lojas</NavLinks>
        </NavItem>
        <NavItem isActive={location.pathname === "/trabalhe-conosco"}>
          <NavLinks to="/trabalhe-conosco">Trabalhe conosco</NavLinks>
        </NavItem>
        <a
          className="buttonFranchisee"
          target="_blank"
          rel="noreferrer"
          href="https://franquia.thebestacai.com.br/cadastro"
          aria-label="rota para a franquia"
        >
          Seja um Franqueado
        </a>
      </NavMenu>
    </NavbarContainer>
  );
};

export default Navbar;
