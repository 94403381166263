import React from "react";
import {
  BarBottom,
  BarTop,
  CircleBottom,
  CircleTop,
  Container,
  Divider,
  Icons,
  IndustryIcon,
  LineBarBottom,
  LineBarTop,
  LineBottom,
  LineTop,
  ShopIcon,
  StartIcon,
  TextContainer,
  TextContent,
  Title,
  TriangleBottom,
  TriangleTop,
  TrophyIcon,
} from "./styles";

export const TimeLine: React.FC = () => {
  return (
    <Container>
      <Divider>
        <LineTop>
          <TextContainer style={{ justifyContent: "flex-end" }}>
            <Title>COMEÇO DE UM SONHO</Title>
            <TextContent>
              Os primeiros passos foram dados na garagem de casa, sem imaginar
              onde chegaríamos.
            </TextContent>
          </TextContainer>
          <TriangleTop />
          <LineBarTop className="firstBar">
            <strong>2018</strong>
          </LineBarTop>
          <BarTop />
          <CircleTop>
            <ShopIcon />
          </CircleTop>
        </LineTop>
      </Divider>
      <Divider>
        <LineBottom>
          <CircleBottom>
            <IndustryIcon />
          </CircleBottom>
          <BarBottom />
          <LineBarBottom>
            <strong>2019</strong>
          </LineBarBottom>
          <TriangleBottom />
          <TextContainer>
            <Title>1ª LOJA FÍSICA</Title>
            <TextContent>
              Em 2019, demos um importante passo rumo à realização do nosso sonho:
              abrimos a nossa primeira loja física. Foi um momento emocionante e
              cheio de expectativas, pois ali todos puderam experimentar pela
              primeira vez a verdadeira essência da Gracco.
            </TextContent>
          </TextContainer>
        </LineBottom>
      </Divider>
      <Divider>
        <LineTop>
          <TextContainer style={{ justifyContent: "flex-end" }}>
            <Title>JÁ COM 3 UNIDADES</Title>
            <TextContent>
              Produzindo em média <b>1000</b> hambúrgueres DIÁRIOS.
            </TextContent>
          </TextContainer>
          <TriangleTop />
          <LineBarTop>
            <strong>2021</strong>
          </LineBarTop>
          <BarTop />
          <CircleTop>
            <TrophyIcon />
          </CircleTop>
        </LineTop>
      </Divider>
      <Divider>
        <LineBottom>
          <CircleBottom>
            <StartIcon />
          </CircleBottom>
          <BarBottom />
          <LineBarBottom className="lastBar">
            <strong>2023</strong>
          </LineBarBottom>
          <TriangleBottom />
          <TextContainer>
            <Title>ATUALMENTE</Title>
            <TextContent>
              Atualmente, contamos com 7 unidades e previsão de 30 lojas para o próximo ano.
            </TextContent>
          </TextContainer>
        </LineBottom>
      </Divider>
    </Container>
  );
};
