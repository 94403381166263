import ImageInsta1 from "../assets/foto_insta.png";
import ImageInsta2 from "../assets/foto_insta2.png";
import ImageInsta3 from "../assets/foto_insta3.png";
import ImageInsta4 from "../assets/foto_insta4.png";
import ImageInsta5 from "../assets/foto_insta5.png";
import ImageInsta6 from "../assets/foto_insta6.png";
import ImageInsta7 from "../assets/foto_insta7.png";
import ImageInsta8 from "../assets/foto_insta8.png";
import ImageInsta9 from "../assets/foto_insta9.png";

export const InstaImgs = [
  {
    id: 1,
    url_image: ImageInsta1,
  },
  {
    id: 2,
    url_image: ImageInsta2,
  },
  {
    id: 3,
    url_image: ImageInsta3,
  },
  {
    id: 4,
    url_image: ImageInsta6,
  },
  {
    id: 5,
    url_image: ImageInsta4,
  },
  {
    id: 6,
    url_image: ImageInsta5,
  },
  {
    id: 7,
    url_image: ImageInsta7,
  },
  {
    id: 8,
    url_image: ImageInsta8,
  },
  {
    id: 9,
    url_image: ImageInsta9,
  },
];
