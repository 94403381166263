import React from "react";
import { TestimonialBox } from "./styles";

interface ITestimonialProps {
  title: string;
  body: React.ReactNode;
  avatar?: any;
  name: string;
  store: string;
  alt?: string;
}

export const Testimonial: React.FC<ITestimonialProps> = ({
  title,
  body,
  avatar,
  name,
  store,
  alt,
}) => {
  return (
    <TestimonialBox>
      <h3>{title}</h3>
      <p className="body">{body}</p>

      <aside>
        <img src={avatar} alt={alt} />

        <div className="content_bottom">
          <span>{name}</span>
          <p>{store}</p>
        </div>
      </aside>
    </TestimonialBox>
  );
};
