import React from "react";
import { Container } from "./styles";

interface IBoxProps {
  img?: string;
  alt?: string;
  text: string;
}

export const Box: React.FC<IBoxProps> = ({ img, text, alt }) => {
  return (
    <Container>
      <img src={img} alt={alt} />
      <p>{text}</p>
    </Container>
  );
};
