import { DefaultTheme, createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle<{ theme: DefaultTheme }>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6, p, a, span{
    color: var(--white);
  }

  h1, h2, h3, h4, h5, h6, title {
    font-family: 'Cairo', sans-serif;
    font-weight: bold;
  }

  p, a, span {
    font-family: 'Baloo Thambi 2';
  } 

  html {
    scroll-behavior: smooth;
  }    

  html, body, #root {
    background: var(--soft-black);
    max-width: 100%;
    max-height: 100%;
    touch-action: manipulation;
    width: 100%;
    height: 100%;
    font: 400 1rem "Roboto", sans-serif;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
  }

  body {
    line-height: 1;
  }

  ul, ol {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  h2 {
    font-size: 2rem;
    @media screen and (max-width: 728px) {
      text-align: center;
    }
  }

  h3{
    font-size: 1.8rem;
  }

  .rec .rec-dot {
    margin-top: 2rem;
    box-shadow: none;
    border: 1px solid var(--principal-color);
    width: 18px;
    height: 18px;

    @media screen and (max-width: 1024px) {
      width: 10px;
      height: 10px;
    }
  }

  .rec .rec-dot_active {
    background-color: var(--principal-color);
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--soft-black);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--black);
    border-radius: 10px;
  }

  :root {
    --white: #fff;
    --soft-white: #f0f0f0;
    --gray: #CBD0DA;
    --principal-color: #37D12A;
    --soft-black: #171717;
    --dark-tone-ink: #0A0A0A;
    --black: #000;
  }
`;

export default GlobalStyles;
