import Navbar from "../../components/Navbar";
import { Banner } from "../../components/Banners";
import BannerTrabalhe from "../../assets/banner_trabalhe.png";
import BannerTrabalheMobile from "../../assets/trabalhe_mobile.png";
import Person from "../../assets/the-best-person.svg";
import Podium from "../../assets/podium.svg";
import People from "../../assets/people-community.svg";

import {
  Boxes,
  ButtonGupy,
  Container,
  ContentCulture,
  ContentSlogan,
  Slogan,
  TextSlogan,
} from "./styles";
import { Footer } from "../../components/Footer";
import { Box } from "../../components/Box";
export const TrabalheConosco = () => {
  return (
    <>
      <Navbar />
      {window.outerWidth > 768 ? (
        <Banner
          img={BannerTrabalhe}
          alt="Banner da página principal. Na foto, mão em desenho segurando um copo de hamburguer"
          text={false}
          page={"trabalhe-conosco"}
        />
      ) : (
        <Banner
          img={BannerTrabalheMobile}
          alt="Banner da página principal. Na foto, mão em desenho segurando um copo de hamburguer"
          text={false}
          page={"trabalhe-conosco"}
        />
      )}
      <Container>
        <span>Quer trabalhar conosco?</span>

        <ContentCulture>
          <h2>
            Nossa <span>cultura</span>
          </h2>
        </ContentCulture>

        <Boxes>
          <Box
            img={People}
            text="Coletivismo"
            alt="ícone do coletivismo que contém três indivíduos desenhados"
          />
          <Box img={Podium} text="Disciplina" alt="ícone de um pódio" />
          <Box
            img={Person}
            text="Atitude"
            alt="ícone de um indivíduo subindo no pódio"
          />
        </Boxes>

        <ContentSlogan>
          <Slogan>
            Ever <span>FRESH</span>, never <span>FROZEN</span>
            <b>.</b>
          </Slogan>

          <TextSlogan>
            Acreditamos firmemente em nossos valores e reconhecemos a
            importância de tudo o que acontece dentro de nossas cozinhas, sendo
            o principal deles o compromisso de servir comida fresca. Além disso,
            a empresa Gracco sempre busca a diversidade e a excelência. Nosso
            ambiente é acolhedor e respeitamos as pessoas como elas são, pois
            acreditamos que, independentemente das diferentes formas de vida
            cotidiana, quando trabalhamos juntos em prol de um produto de
            qualidade, conseguimos criar algo surpreendente e proporcionar uma
            experiência fantástica aos nossos clientes.
          </TextSlogan>

          <ButtonGupy
            target="_blank"
            rel="noreferrer"
            href="https://graccoburguer.gupy.io/"
            aria-label="rota para a gupy"
          >
            Veja nossas vagas
          </ButtonGupy>
        </ContentSlogan>
      </Container>
      <Footer />
    </>
  );
};
