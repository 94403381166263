import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Sobre } from "../pages/Sobre";
import { Home } from "../pages/Home";
import { Lojas } from "../pages/Lojas";
import { TrabalheConosco } from "../pages/TrabalheConosco";
import { Privacidade } from "../pages/Privacidade";

const Router = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route path="/lojas" element={<Lojas />} />
        <Route path="/trabalhe-conosco" element={<TrabalheConosco />} />
        <Route path="/privacidade" element={<Privacidade />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
