import styled, { css } from "styled-components";
interface IProps {
  text?: boolean;
  img?: string;
}
export const Container = styled.div<IProps>`
  display: flex;
  justify-content: center;
  width: 100%;

  ${({ text, img }) => {
    if (text) {
      return css`
        background: url("${img}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: flex-start;
      `;
    }
  }}
`;

export const Img = styled.img`
  height: 100vh;
  position: relative;
  width: 100%;
  padding-right: 0;
  border: 0;
  object-fit: cover;
  overflow: hidden;
`;

export const ScrollIndicator = styled.a`
  position: absolute;
  bottom: 20px;
  left: 50%;
  color: white;
  font-size: 2.4rem;
  line-height: 0.8;
  animation: pulse 1s infinite;
  transition: 0.3s;
  cursor: pointer;

  @keyframes pulse {
    0% {
      transform: scale(1.5);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1.5);
    }
  }
`;

export const ContentText = styled.div`
  padding-top: 8.5%;
  padding-left: 5%;
  width: 35%;
  h1 {
    margin: 0;
    color: var(--white);
    text-transform: uppercase;

    @media screen and (max-width: 500px) {
      height: 36%;
    }
  }
  @media screen and (max-width: 1350px) {
    width: 45%;
  }
  @media screen and (max-width: 1000px) {
    width: 60%;
  }
  @media screen and (max-width: 600px) {
    width: 55%;
  }
  @media screen and (max-width: 575px) {
    width: 90%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
  }
`;

export const Text = styled.h1`
  display: flex;
  text-align: justify;
`;

