import avatar1 from "../assets/cliente1.png";
import avatar2 from "../assets/cliente2.png";
import avatar3 from "../assets/cliente3.png";

export const items = [
  {
    avatar: avatar2,
    title: "Apaixonada",
    body: "Sobre um lugar que eu conheci e me apaixonei, atendimento nota 10, espaço bacana e aconchegante. Qualidade de tudo sem explicação...",
    name: "Júlia Fidelis",
    store: "cliente",
    alt: "Imagem de uma cliente loira, acompanhada de seu possível companheiro",
    key: 2,
  },
  {
    avatar: avatar1,
    title: "Recomendo muito",
    body: "O lugar é bem bacana, o lanche e acompanhamentos são sensacionais, vale muito a pena conhecer!",
    name: "Tatiane Siqueira",
    store: "cliente",
    alt: "Imagem de uma cliente com cabelos pretos, caucasiana",
    key: 1,
  },

  {
    avatar: avatar3,
    title: "Sabor sem igual",
    body: "É um lugar muito aconchegante de ótimo ambiente, para se estar com família e amigos. O lanche é de um sabor indescritível, apenas comendo para saber a sensação que é uma mordida nesse hambúrguer maravilhoso e com uma maionese sensacional e muito saborosa.",
    name: "Gabriel Ott",
    store: "cliente",
    alt: "Cliente com barba, cabelos escuros e pele caucasiana",
    key: 2,
  },
];
