import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Carousel from "react-elastic-carousel";
import Delivery from "../../assets/delivery.svg";
import FotoLanche from "../../assets/livefast.jpg";
import BannerImg from "../../assets/banner_inicio.png";
import BannerMobile from "../../assets/banner_cellphone.png";
import { Footer } from "../../components/Footer";
import {
  AboutApp,
  Container,
  ContainerSwiper,
  ContentAbout,
  ContentAppAbout,
  ContentGalery,
  ContentStickerLeft,
  ContentStickers,
  ContentSwiper,
  ContentTestimonial,
  HeadText,
  Images,
  InstaIcon,
  InstagramButton,
  PriceText,
  Stickers,
  StyledCardContainer,
  StyledImage,
  TextBox,
  Title,
} from "./styles";
import { Fix } from "../../styles/icons";
import { InstaImgs } from "../../utils/images";
import { Banner } from "../../components/Banners";
import { Testimonial } from "../../components/Testimonial";
import { items } from "../../utils/testimonials";

export const Home = () => {
  const [clickCount, setClickCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (url: string) => {
    if (isMobile) {
      if (clickCount === 1) {
        window.open(url);
      } else {
        setClickCount(clickCount + 1);
      }
    } else {
      window.open(url);
    }
  };

  return (
    <Container>
      <Navbar />

      {window.outerWidth > 768 ? (
        <Banner
          img={BannerImg}
          alt="Banner da página principal. Na foto, mão em desenho segurando um copo de hamburguer"
          text={false}
          page={"home"}
        />
      ) : (
        <Banner
          img={BannerMobile}
          alt="Banner da página principal. Na foto, mão em desenho segurando um copo de hamburguer"
          text={false}
          page={"home"}
        />
      )}

      <ContentAbout>
        <ContentStickers>
          <ContentStickerLeft>
            <Title>
              <b>We are not a fast food restaurant.</b> Live fast, eat{" "}
              <b className="text">SLOWLY</b>
            </Title>
            <p>
              Nós não somos apenas um restaurante fast food. Utilizamos
              ingredientes frescos e receitas inspiradoras! Por trás de cada
              mordida, queremos transmitir a hospitalidade que desejamos
              oferecer a vocês. Estamos sempre nos atualizando e buscando
              entregar o melhor do mundo dos hambúrgueres em cada um dos nossos
              burgers!
            </p>
          </ContentStickerLeft>
          <Stickers src={FotoLanche} alt="Sticker Gracco" />
        </ContentStickers>

        <AboutApp>
          <StyledCardContainer>
            <StyledImage src={Delivery} alt="delivery" />

            {window.outerWidth > 768 ? (
              <p className="extra">Para mais informações, passe o mouse</p>
            ) : (
              <p className="extra">Para mais informações, clique aqui</p>
            )}

            <TextBox>
              <HeadText>Acesse!</HeadText>
              <a
                className="buttonLink"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  handleClick(
                    "https://www.cardapiodigital.io/graccoexpress-Apucarana"
                  )
                }
                aria-label="catalogo de Apucarana"
              >
                Gracco Apucarana
              </a>
              <a
                aria-label="catalogo de Maringá"
                className="buttonLink"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  handleClick(
                    "https://www.cardapiodigital.io/463ce774-0f34-4d8b-84db-2abaa7b4a6c8/catalog"
                  )
                }
              >
                Gracco Maringá
              </a>
              <a
                className="buttonLink"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  handleClick(
                    "https://www.cardapiodigital.io/graccoburgeribipora/catalog"
                  )
                }
                aria-label="catalogo de Ibiporã"
              >
                Gracco Ibiporã
              </a>
              <a
                className="buttonLink"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  handleClick(
                    "https://www.cardapiodigital.io/graccoburgerlondrina/catalog"
                  )
                }
                aria-label="catalogo de Londrina"
              >
                Gracco Londrina
              </a>
              <PriceText>Linktree</PriceText>
            </TextBox>
          </StyledCardContainer>
          <ContentAppAbout>
            <h2>Nossas lojas</h2>
            <p>
              Nossas lojas são o resultado de um sonho que se tornou realidade.
              Começamos em uma garagem, e hoje, contamos com várias unidades
              espalhadas pelo estado. Em cada uma delas, buscamos oferecer a
              melhor experiência Gracco, com ingredientes frescos e receitas
              inspiradoras. Queremos que todos os clientes se sintam bem-vindos
              e apreciem nossos deliciosos hambúrgueres. Nosso objetivo é levar
              a qualidade e o sabor único da Gracco a todos que desejam saborear
              um verdadeiro prazer gastronômico.
            </p>
          </ContentAppAbout>
        </AboutApp>
      </ContentAbout>

      <ContentGalery>
        <h3>Galeria</h3>

        <Images>
          {InstaImgs.map((image) => (
            <img
              key={image?.id}
              src={image?.url_image}
              alt={`Imagens do instagram ${image.id}`}
            />
          ))}
        </Images>
        <InstagramButton
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/graccoburger/"
          aria-hidden="true"
          aria-label="rota para o instagram"
        >
          Ver Instagram <InstaIcon alt="iImagem ilustrativa do instagram" />
        </InstagramButton>
      </ContentGalery>

      <ContainerSwiper>
        <h3>Depoimentos</h3>
      </ContainerSwiper>

      <ContentSwiper>
        <ContentTestimonial>
          {/* @ts-ignore */}
          <Carousel
            isRTL
            showArrows={false}
            enableAutoPlay={false}
            itemsToShow={window.innerWidth > 1024 ? 2 : 1}
          >
            {items.map((item) => (
              <Testimonial
                avatar={item.avatar}
                title={item.title}
                body={
                  <>
                    {item?.body.length < 160 ? (
                      item.body
                    ) : (
                      <>{item.body.slice(0, 160)}</>
                    )}
                  </>
                }
                name={item.name}
                store={item.store}
                alt={item.alt}
                key={item.key}
              />
            ))}
          </Carousel>
        </ContentTestimonial>
      </ContentSwiper>

      <Footer />
    </Container>
  );
};
