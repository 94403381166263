import styled from "styled-components";

export const Container = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2337D12A' stroke-width='22' stroke-dasharray='30%2c 30%2c 40' stroke-dashoffset='90' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  padding: 2rem;
  width: 12.5rem;
  height: 12.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 4rem;
    height: 4rem;

    @media screen and (max-width: 1024px) {
      width: 3rem;
      height: 3rem;
    }
  }

  p {
    font-size: 1.5rem;
    margin-top: 0.6rem;
    letter-spacing: normal;

    @media screen and (max-width: 1024px) {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 11rem;
    height: 11rem;
  }
  @media screen and (max-width: 768px) {
    margin: 2rem 0;

    :first-child, :last-child{
        margin: 0;
    }
  }
`;
