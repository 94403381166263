import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 10%;
  flex-direction: column;
  width: 100%;
  font-size: 1.5rem;
`;

export const ContentCulture = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;

  h2 {
    font-size: 4rem;

    @media screen and (max-width: 768px) {
      font-size: 2.2rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 1.4rem;
    }
  }

  span {
    color: var(--principal-color);
  }

  @media screen and (max-width: 768px) {
    padding-top: 1rem;
  }
`;

export const Boxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 6rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 2rem 0;
  }
`;

export const InnerBox = styled.img`
  width: 13rem;
  height: 13rem;
`;

export const ContentSlogan = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
    padding-top: 1rem;
  }
  @media screen and (max-width: 500px) {
    align-items: flex-start;
  }
`;

export const Slogan = styled.h4`
  font-size: 4rem;
  letter-spacing: 0;

  span {
    color: var(--principal-color);
    text-transform: uppercase;
  }
  b {
    font-size: 4rem;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 2.2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.5rem;
  margin: 2rem 0 2.5rem 0;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
`;

export const TextSlogan = styled.article`
  font-size: 1.25rem;
  text-align: center;
  line-height: 2rem;
  color: var(--white);

  @media screen and (max-width: 768px) {
    margin: 0;
    text-align: left;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.1rem;
  }
`;

export const ButtonGupy = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--principal-color);
  color: var(--white);
  border: 0;
  border-radius: 5px;
  padding: 1.3rem 2rem 1rem;
  margin-top: 3rem;
  font-size: 1.25rem;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  :hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    70% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
`;
