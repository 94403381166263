import styled, { keyframes, css } from "styled-components";
import { Link } from "react-router-dom";
import { Fix, MenuCenter } from "../../styles/icons";

interface IMobileProps {
  open: boolean;
}

interface INavItemProps {
  isActive: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

export const NavbarContainer = styled.nav`
  background-color: var(--dark-tone-ink);
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 3.5rem;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;

  @media screen and (max-width: 768px) {
    padding: 1.5rem 3rem;
  }
  @media screen and (max-width: 500px) {
    padding: 2rem 3rem;
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;

  @media screen and (max-width: 1024px) {
    width: 10%;
  }
  @media screen and (max-width: 768px) {
    width: 20%;
  }
`;

export const NavMenu = styled.ul<IMobileProps>`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 70%;
  align-items: center;

  @media screen and (max-width: 1500px) {
    width: 80%;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    align-items: center;
    width: 100%;
    height: 91vh;
    position: absolute;
    top: 100%;
    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: all 0.5s ease;
    background-color: var(--soft-black);
    padding: 4rem 0;
    overflow: hidden;
    right: ${({ open }) => (open ? 0 : "100%")};
    display: ${({ open }) => (open ? "flex" : "none")};
    animation: ${({ open }) => (open ? fadeIn : fadeOut)} 0.5s ease-in-out;
  }

  .buttonFranchisee {
    font-weight: 700;
    font-size: 1rem;
    width: 84%;
    color: var(--white);
    text-decoration: none;
    display: flex;
    justify-content: center;
    background: var(--principal-color);

    padding: 0.5rem 2rem;
    border-radius: 1rem;
    @media screen and (max-width: 1400px) {
      font-size: 0.9rem;
      width: 100%;
    }
    @media screen and (max-width: 1024px) {
      margin-left: 0;
    }
    :hover {
      animation: pulse 1s infinite;
      transition: 0.3s;
    }
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      70% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

export const NavItem = styled.li<INavItemProps>`
  width: 100%;

  :first-child {
    text-align: center;
  }

  ${(props) =>
    props.isActive &&
    css`
      a {
        color: var(--principal-color);
        text-decoration: none;
      }
    `}

  @media screen and (max-width: 1024px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NavLinks = styled(Link)`
  color: var(--white);
  text-decoration: none;
  font-size: 1.3rem;

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 1300px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: -20px;
    right: 2%;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const MenuIcon = styled(MenuCenter)<IMobileProps>`
  width: 3rem;
  height: 3rem;
  color: var(--white);
  transition: transform 0.3s ease;

  ${({ open }) => open && "transform: rotate(180deg)"}

  @media screen and (max-width: 768px) {
    width: 2rem;
  }
`;

export const CloseIcon = styled(Fix)<IMobileProps>`
  width: 3rem;
  height: 3rem;
  color: var(--white);
  transition: transform 0.3s ease;

  ${({ open }) => open && "transform: rotate(180deg)"}
  @media screen and (max-width: 768px) {
    width: 2rem;
  }
`;
