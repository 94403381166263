import styled, { css, keyframes } from "styled-components";
import { Instagram } from "../../styles/icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentGalery = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;

  h3 {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 1100px) {
    padding-top: 4rem;
  }
  @media screen and (max-width: 500px) {
    h3 {
      font-size: 1.4rem;
    }
  }
`;

export const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 23px;
  grid-row-gap: 23px;
  margin-bottom: 2rem;

  img {
    width: 25rem;
    height: 25rem;
    border-radius: 5px;
    object-fit: cover;

    @media screen and (max-width: 1700px) {
      width: 20rem;
      height: 20rem;
    }
    @media screen and (max-width: 1400px) {
      width: 15rem;
      height: 15rem;
    }
    @media screen and (max-width: 900px) {
      :last-child {
        display: none;
      }
    }
    @media screen and (max-width: 600px) {
      width: 10rem;
      height: 10rem;
    }
    @media screen and (max-width: 400px) {
      width: 8rem;
      height: 8rem;
    }
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
`;

export const ImgInstagram = styled.img`
  margin-left: 0.2rem;
`;

export const InstagramButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  font-size: 1rem;
  color: var(--white);
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  cursor: pointer;

  &:hover {
    color: var(--white);
    transform: scale(1.1);
  }
`;

export const ContentSwiper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0 6rem;

  @media screen and (max-width: 900px) {
    padding: 4rem 3rem 6rem;
  }
`;

export const ContainerSwiper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 500px) {
    h3 {
      font-size: 1.4rem;
    }
  }
`;

export const ContentTestimonial = styled.div`
  width: 100%;
  .body {
    display: inline-block;
    flex-direction: column;
  }
  .moreTestimonial {
    color: var(--white);

    :hover {
      cursor: pointer;
    }
  }
`;

export const StyledCardContainer = styled.div`
  width: 250px;
  height: 100%;
  padding: 1rem 1rem 2rem;
  background: #313131;
  border-radius: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease-in-out;

  .extra {
    font-size: 0.8rem;
    text-align: center;
  }

  &:hover {
    transform: scale(1) rotate(0deg);
    .extra {
      color: transparent !important;
    }
  }
`;

export const TextAnimation = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
`;

export const StyledImage = styled.img`
  height: 30%;
  position: absolute;
  transition: 0.2s ease-in-out;
  z-index: 1;

  ${StyledCardContainer}:hover & {
    height: 65%;
    filter: blur(7px);
    animation: ${TextAnimation} 3s infinite;
  }
`;

export const TextBox = styled.div`
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: 0.2s ease-in-out;
  z-index: 2;

  ${StyledCardContainer}:hover & {
    opacity: 1;
  }

  .buttonLink {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;

    cursor: pointer;

    border: 0;
    border-radius: 10px;
    background-color: var(--soft-black);
    color: var(--white);
    justify-content: center;
  }
`;

export const Text = styled.p`
  font-weight: bold;
`;

export const HeadText = styled(Text)`
  font-size: 20px;
`;

export const PriceText = styled.h4`
  font-size: 17px;
  font-weight: bold;
`;

export const DescriptionText = styled.span`
  font-size: 12px;
  color: lightgrey;
`;

export const ContentStickers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 8%;

  @media screen and (max-width: 1500px) {
    padding: 3rem 11%;
  }
  @media screen and (max-width: 1300px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 1100px) {
    padding-top: 4rem;
    display: flex;
  }
  @media screen and (max-width: 900px) {
    padding: 2rem 15%;
    align-items: center;
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    margin: 0 -2rem;
  }
`;

export const ContentStickerLeft = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.5rem;
    width: 38rem;

    @media screen and (max-width: 1600px) {
      width: 40rem;
      font-size: 1.3rem;
    }
    @media screen and (max-width: 1400px) {
      width: 30rem;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      text-align: left;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
`;

export const Stickers = styled.img`
  width: 45rem;
  height: 29rem;
  object-fit: cover;

  @media screen and (max-width: 1700px) {
    width: 28rem;
    height: 25rem;
    margin-top: 1rem;
  }

  @media screen and (max-width: 1500px) {
    width: 25rem;
    height: 20rem;
  }
  @media screen and (max-width: 1500px) {
    width: 22rem;
    height: 22rem;
  }
  @media screen and (max-width: 1024px) {
    object-fit: cover;
    margin-top: 2rem;
    z-index: -1;
    width: 65%;
    height: 15rem;
    overflow: hidden;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const Title = styled.h1`
  .text {
    color: var(--principal-color);
  }
  @media screen and (max-width: 1200px) {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 1.2rem;
    font-size: 1.6rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }
  @media screen and (max-width: 500px) {
    text-align: left;
    font-size: 1.4rem;
  }
`;
export const ContentAppAbout = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  @media screen and (max-width: 768px) {
    width: 80%;
  }

  h2 {
    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
  p {
    font-size: 1.2rem;
    @media screen and (max-width: 1500px) {
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }
`;

export const AboutApp = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8rem;
  background: linear-gradient(
    var(--soft-black) 82%,
    var(--principal-color) 82% 84%,
    var(--soft-black) 84%
  );

  margin: 0 -16.25rem;
  padding: 3rem 25%;

  @media screen and (max-width: 1500px) {
    margin: 0 -13rem;
  }
  @media screen and (max-width: 1300px) {
    margin: 0 -10rem;
  }
  @media screen and (max-width: 1200px) {
    margin: 0 -8rem;
  }
  @media screen and (max-width: 1100px) {
    padding-top: 4rem;
    display: flex;
  }
  @media screen and (max-width: 900px) {
    margin: 0 -4rem;
    flex-direction: column-reverse;
    align-items: center;
    padding: 2rem 5%;
  }

  @media screen and (max-width: 768px) {
    margin: 0 -2rem;
    padding-top: 1rem;
  }
`;

export const ContentAbout = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

export const InstaIcon = styled(Instagram)`
  width: 1.2rem;
  height: 1.2rem;
  margin-left: .4rem;
  fill: white;
`;
