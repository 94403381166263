import React from "react";
import {
  AllContents,
  ButtonBox,
  ButtonBoxButton,
  ButtonBoxText,
  Container,
  ContentTopFooter,
  FacebookIcon,
  FooterContainer,
  Imprensa,
  InstagramIcon,
  Line,
  Midia,
  Regulamentation,
  TopContent,
} from "./styles";
import { Fix } from "../../styles/icons";

interface IFooter {
  active?: boolean;
}

export const Footer: React.FC<IFooter> = ({ active }) => {
  return (
    <Container>
      <ButtonBox active={active}>
        <ButtonBoxText>Venha fazer parte da nossa história</ButtonBoxText>
        <ButtonBoxButton
          target="_blank"
          rel="noreferrer"
          href="https://franquia.thebestacai.com.br/cadastro"
          aria-label="Link para cadastro de franqueado"
        >
          Seja um Franqueado
        </ButtonBoxButton>
      </ButtonBox>
      <FooterContainer>
        <TopContent>
          <AllContents>
            <ContentTopFooter>
              <a href="/sobre" aria-label="rota para o inicio da pagina">
                Sobre a Gracco Burguer
              </a>
              <a
                href="/trabalhe-conosco"
                aria-label="rota para a tela de trabalhe conosco"
              >
                Trabalhe conosco
              </a>
              <a
                aria-label="redirecionamento para a pagina de franqueados"
                className="buttonFranchisee"
                target="_blank"
                rel="noreferrer"
                href="https://franquia.thebestacai.com.br/cadastro"
              >
                Seja um Franqueado
              </a>
            </ContentTopFooter>

            <Midia>
              <a
                aria-label="rota para o facebook da gracco burger"
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/graccoburguer/?locale=pt_BR"
              >
                <FacebookIcon size={25} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/graccoburger/"
                aria-label="rota para o instagram da gracco burger"
              >
                <InstagramIcon size={30} />
              </a>
            </Midia>
          </AllContents>

          <Imprensa>
            <span>Assessoria de Imprensa </span>
            <span>Julia Nascimento</span>
            <span>comunicacao@thebestacai.com.br</span>
          </Imprensa>

          <Line />

          <Regulamentation>
            <a href="/privacidade" aria-label="rota para a pagina de privacidade">Privacidade de dados</a>
          </Regulamentation>
        </TopContent>
      </FooterContainer>
    </Container>
  );
};
