import React, { useRef } from "react";
import {
  Container,
  ContentText,
  Img,
  ScrollIndicator,
} from "./styles";

interface IBannerProps {
  img: string;
  alt: string;
  text?: boolean;
  page?: string;
  description?: string;
}

export const Banner: React.FC<IBannerProps> = ({
  img,
  alt,
  text,
  page,
  description,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    if (containerRef.current) {
      window.scrollTo({
        top: containerRef.current.offsetTop + 1000,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {text ? (
        <Container text={text} img={img} ref={containerRef}>
          <ContentText>
            <h1 className={`${page}`}>{description}</h1>
          </ContentText>
        </Container>
      ) : (
        <Container ref={containerRef}>
          <Img src={img} alt={alt} />
        </Container>
      )}

      <ScrollIndicator aria-label="scroll" onClick={handleClick}>
        ↓
      </ScrollIndicator>
    </>
  );
};
