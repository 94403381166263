import styled, { css } from "styled-components";
import { Facebook, Instagram } from "../../styles/icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`;
export const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--black);
  width: 100%;
  height: 22.75rem;
  padding: 3.5rem 8rem 3.5rem;

  @media screen and (max-width: 1400px) {
    padding: 2.5rem 4rem 3.5rem;
  }
  @media screen and (max-width: 1100px) {
    padding: 1rem 4rem 2rem;
  }
  @media screen and (max-width: 1100px) {
    padding: 1rem 4rem 0.5rem;
  }
`;

export const ContentTopFooter = styled.div`
  display: flex;

  a + a {
    cursor: pointer;
    margin-left: 1.25rem;
  }

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    a + a {
      cursor: pointer;
      margin-left: 0;
    }
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  width: 100%;
  margin-top: 2.5rem;

  @media screen and (max-width: 1024px) {
    align-items: center;
  }

  p,
  a {
    cursor: pointer;
    color: var(--white);
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0;
    text-decoration: none;

    @media screen and (max-width: 1600px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 500px) {
      font-size: 0.9rem;
      margin-bottom: 0.8rem;
    }

    &:hover {
      display: inline-block;
      position: relative;
      color: var(--white);
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--white);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  p + p,
  p + a {
    margin-left: 9rem;

    @media screen and (max-width: 1600px) {
      margin-left: 4rem;
    }
    @media screen and (max-width: 1200px) {
      margin-left: 2rem;
    }
    @media screen and (max-width: 1100px) {
      margin-left: 0;
    }
  }
`;

export const AllContents = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;
export const Midia = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;

  a + a {
    cursor: pointer;
    margin-left: 1.25rem;
  }

  @media screen and (max-width: 1100px) {
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
  }
  @media screen and (max-width: 500px) {
    margin-top: 1rem;
  }
`;

export const Line = styled.hr`
  border-bottom: 2px solid var(--white);
  width: 100%;
`;

export const Regulamentation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  .first-link {
    margin-right: 1rem;

    @media screen and (max-width: 1100px) {
      margin: 0;
    }
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  @media screen and (max-width: 1100px) {
    justify-content: space-around;
  }

  a {
    cursor: pointer;
    color: var(--white);
    font-size: 0.93rem;
    letter-spacing: 0rem;

    &:hover {
      display: inline-block;
      position: relative;
      color: var(--white);
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--white);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    @media screen and (max-width: 500px) {
      font-size: 0.7rem;
    }
  }
`;

interface IProps {
  active?: boolean;
}

export const ButtonBox = styled.div<IProps>`
  ${({ active }) => {
    if (active) {
      return css`
        position: relative;
        top: 2.5rem;

        display: flex;
        justify-content: space-evenly;
        align-items: center;

        width: 60%;
        height: 5.9rem;
        padding: 0.5rem;

        background: var(--principal-color);
        border-radius: 0.2rem;

        @media screen and (max-width: 1100px) {
          width: 70%;
        }
        @media screen and (max-width: 680px) {
          width: 80%;
        }
        @media screen and (max-width: 575px) {
          padding: 1rem;
        }
        @media screen and (max-width: 500px) {
          flex-direction: column;
          height: 5rem;
          padding: 2rem 0;
        }
      `;
    } else {
      return css`
        display: none;
      `;
    }
  }}
`;

export const ButtonBoxText = styled.span`
  color: var(--black);
  font-size: 1.5rem;
  padding: 0.5rem 0.1rem 0;
  margin-right: 0.4rem;
  @media screen and (max-width: 1490px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 1210px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 700px) {
    font-size: 0.9rem;
    margin-right: 0.2rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 0.8rem;
    margin-right: 0.1rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
    text-align: center;
  }
`;
export const ButtonBoxButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18rem;
  background: var(--black);
  color: var(--white);
  font-size: 1.5rem;
  padding: 0.5rem 0.5rem 0.2rem;
  text-decoration: none;
  box-shadow: none;
  border: none;
  border-radius: 0.2rem;

  :active,
  ::selection {
    box-shadow: none;
    border: none;
  }
  :hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1490px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 1210px) {
    font-size: 1.1rem;
    width: 14rem;
  }
  @media screen and (max-width: 940px) {
    font-size: 0.9rem;
    width: 10rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 0.8rem;
    width: 9rem;
  }
  @media screen and (max-width: 500px) {
    text-align: center;
    width: 10rem;
    font-size: 0.8rem;
  }
`;

export const Imprensa = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    justify-content: center;
    align-items: center;
  }

  span {
    color: var(--white);
    font-size: 0.93rem;
    letter-spacing: 0rem;

    margin-bottom: 0.3rem;

    @media screen and (max-width: 500px) {
      font-size: 0.7rem;
    }
  }
`;

export const Icons = css`
  width: 2rem;
  height: 2rem;

  fill: var(--white);
`;

export const InstagramIcon = styled(Instagram)`
  ${Icons}
  color: white;
`;
export const FacebookIcon = styled(Facebook)`
  ${Icons}
`;
