import styled, { css } from "styled-components";

import {
  BarGraphRounded,
  UserStart,
  Trophy,
  UserGraduate,
  PeopleCommunity,
  HandsShake,
  AscentPerson,
  UserFlag,
  Timer,
  Settings,
  Family,
  MapPin,
  Shop,
} from "thebest-icons";

export const PageContainer = styled.div``;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  padding: 1rem 8%;

  @media screen and (max-width: 575px) {
    padding-top: 1.5rem;
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 3rem;

  p {
    padding: 0 6.8rem;
    margin-bottom: 2.6rem 0;
    text-align: center;
    width: 100%;
    color: var(--white);
    font-size: 1.5rem;
  }

  @media screen and (max-width: 575px) {
    p {
      padding: 0;
      text-align: start;
    }
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 0;
    p {
      font-size: 1rem;
    }
  }
`;

export const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
  letter-spacing: 0;
  strong {
    font-weight: 700;
  }

  @media screen and (max-width: 900px) {
    text-align: center;
    font-size: 2rem;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 0;
    font-size: 1.3rem;
  }
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;

  margin: 2rem 0;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: 1000px) {
    grid-template-rows: 50% 50%;
  }
  @media screen and (max-width: 575px) {
    grid-auto-flow: row;
    grid-template-rows: auto;
    margin: 1rem 0;
  }
`;

export const IconsCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;
  margin: 0 3.5rem;
  height: fit-content;

  @media screen and (max-width: 1000px) {
    &:first-child {
      margin-left: 3.5rem;
    }
    &:last-child {
      margin-right: 3.5rem;
    }
  }
`;

export const IconTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1.7rem;
  min-width: 8.6rem;

  color: var(--white);
  font-size: 1.25rem;
  font-weight: 400;

  b {
    text-align: center;
    font-weight: 500;
  }
  strong {
    font-weight: 700;
  }
  @media screen and (max-width: 1400px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 1000px) {
    font-size: 1rem;
  }
`;

export const IconText = styled.div`
  text-align: center;
  font-weight: 400;
  color: var(--gray-150);
  @media screen and (max-width: 1400px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 1000px) {
    font-size: 1rem;
  }
`;

export const TrajectoryContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 100%;

  margin: 1.5rem 0;

  @media screen and (max-width: 1560px) {
    grid-template-columns: 38% 62%;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: 40% 60%;
  }
  @media screen and (max-width: 880px) {
    grid-auto-flow: row;
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
  @media screen and (max-width: 500px) {
    margin: 0.5rem 0;
  }
`;

export const TrajectoryImg = styled.img`
  width: 100%;
  height: 33rem;
  overflow: hidden;
  object-fit: cover;

  @media screen and (max-width: 900px) {
    height: 20rem;
  }
  @media screen and (max-width: 500px) {
    z-index: 2;
    position: center;
    width: 100%;
  }
`;

export const TrajectoryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2%;
  @media screen and (max-width: 1080px) {
    padding: 2%;
  }
  @media screen and (max-width: 880px) {
    padding: 2% 0;
    justify-content: left;
  }
`;

export const TrajectoryTitle = styled.h1`
  font-size: 3.7rem;
  font-weight: 400;
  margin: 0;
  strong {
    color: var(--principal-color);
  }
  @media screen and (max-width: 1760px) {
    font-size: 3.2rem;
  }
  @media screen and (max-width: 1400px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 1200px) {
    font-size: 2.5rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 2rem;
  }
`;

export const TrajectoryText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: justify;
  height: 100%;
  color: var(--white);
  p {
    font-size: 1.25rem;
  }
  @media screen and (max-width: 1080px) {
    padding-top: 2%;
  }
  @media screen and (max-width: 880px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 575px) {
    p {
      text-align: start;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  @media screen and (max-width: 400px) {
    p {
      font-size: 1.2rem;
    }
  }
`;

export const TimeLineContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 55rem;
  width: 100%;
  margin: 1.5rem;

  @media screen and (max-width: 600px) {
    height: 85rem;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 1.5rem;
`;
export const VideoRep = styled.iframe`
  width: 866px;
  height: 487px;
  border: none;
  @media screen and (max-width: 900px) {
    width: 579px;
    height: 326px;
  }
  @media screen and (max-width: 575px) {
    width: 435px;
    height: 245px;
  }
  @media screen and (max-width: 400px) {
    width: 335px;
    height: 245px;
  }
`;
export const PrinciplesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 1.5rem 0 3rem;
`;

export const PrinciplesCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;

  @media screen and (max-width: 1180px) {
    width: 100%;
  }
  @media screen and (max-width: 690px) {
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
`;

export const PrinciplesCards = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 18rem;
  margin: 0.5rem 0;
  @media screen and (max-width: 1400px) {
    height: 25rem;
  }
  @media screen and (max-width: 1300px) {
    height: 24rem;
  }
  @media screen and (max-width: 1010px) {
    height: 28rem;
  }
  @media screen and (max-width: 920px) {
    height: 30rem;
  }

  @media screen and (max-width: 690px) {
    height: 100%;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const PrinciplesIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
`;
export const PrinciplesIcon = styled.div`
  padding: 0.2rem;

  height: 5rem;
  @media screen and (max-width: 1100px) {
    height: 4rem;
  }
  @media screen and (max-width: 860px) {
    height: 3rem;
  }
  @media screen and (max-width: 660px) {
    height: 5rem;
  }
  @media screen and (max-width: 400px) {
    height: 4rem;
  }
`;

export const PrinciplesTextContainer = styled.div`
  padding: 0.5rem 1rem;
`;

export const PrinciplesTitle = styled.h1`
  font-size: 1.5rem;
  letter-spacing: 0;
  margin-bottom: 1rem;
  @media screen and (max-width: 575px) {
    text-align: center;
    font-size: 1.1rem;
  }
`;

export const PrinciplesText = styled.p`
  font-size: 1rem;
  letter-spacing: 0;
  @media screen and (max-width: 575px) {
    text-align: justify;
  }
`;

export const IconContent = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Icons = css`
  width: 3rem;
  height: 3rem;

  color: var(--white);
`;

export const BarGraphIcon = styled(BarGraphRounded)`
  ${Icons}
`;
export const StartIcon = styled(UserStart)`
  ${Icons}
`;
export const TrophyIcon = styled(Trophy)`
  ${Icons}
`;
export const UserGraduateIcon = styled(UserGraduate)`
  ${Icons}
`;
export const FamilyIcon = styled(Family)`
  ${Icons}
`;
export const HandsIcon = styled(HandsShake)`
  ${Icons}
`;
export const AscentIcon = styled(AscentPerson)`
  ${Icons}
`;
export const UserFlagIcon = styled(UserFlag)`
  ${Icons}
`;
export const TimerIcon = styled(Timer)`
  ${Icons}
`;
export const SettingsIcon = styled(Settings)`
  ${Icons}
`;
export const StoreIcon = styled(Shop)`
  ${Icons}
`;
export const GraphicIcon = styled(BarGraphRounded)`
  ${Icons}
`;
export const MapPinIcon = styled(MapPin)`
  ${Icons}
`;
export const PeopleCommunityIcon = styled(PeopleCommunity)`
  ${Icons}
`;
